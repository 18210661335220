import React, {Component} from "react";

export default class TaskHistoryTable extends Component {
  render() {
    return (<div class="bottom-table scroll">
      <h3>History</h3>
      <table className="history-table">
        {
          this.props.history.map(data => {
            return (<tr>
              <td>{data.task}</td>
              <td>{data.counter}</td>
              <td>{new Date(data.timestamp).toUTCString()}</td>
            </tr>);
          })
        }
      </table>
    </div>);
  }
}
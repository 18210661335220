import React, {Component} from "react";

function secondsToTime(seconds) {
  const arr = new Date(seconds * 1000).toISOString().substr(11, 8).split(":");

  const days = Math.floor(seconds / 86400);
  arr[0] = parseInt(arr[0], 10) + days * 24;

  return arr.join(":");
}

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timerActive: false,
      counter: 0
    };
  }

  pauseTimer() {
    this.setState({
      timerActive: false
    }, () => {
      clearTimeout(this.t);
    });
  }

  incrementCounter() {
    this.setState({
      timerActive: true,
      counter: this.state.counter + 1
    }, () => {
      this.t = setTimeout(() => {
        this.incrementCounter();
      }, 1000);
    });
  }

  resetTimer() {
    this.props.resetTask();
    this.setState({
      timerActive: false,
      counter: 0
    }, () => {
      clearTimeout(this.t);
    });
  }

  completedTask() {
    let timeInSec = this.state.counter;
    this.props.completedTask(secondsToTime(timeInSec));
    this.resetTimer();
  }

  render() {
    document.title = secondsToTime(this.state.counter) || "TimeIt";
    return (<div>
      <p className={this.state.timerActive
          ? "counter active"
          : "counter inactive"
}>
        {secondsToTime(this.state.counter)}
      </p>

      {
        !this.state.timerActive
          ? (<button onClick={this.incrementCounter.bind(this)}>Start</button>)
          : (<button onClick={this.pauseTimer.bind(this)}>Pause</button>)
      }

      <button onClick={this.resetTimer.bind(this)}>Reset</button>
      <button onClick={this.completedTask.bind(this)}>Completed</button>
    </div>);
  }
}
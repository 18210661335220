import React, {
  Component
} from "react";

export default class TaskInput extends Component {

  constructor(props) {
    super(props);
    this.state = {
      placeholder:"Enter your task.",
      task:""
    }
  }

  keyPressHandler(e) {
    if (e.key === 'Enter') {
      this.props.setTask(e.currentTarget.innerHTML);
    }
  }

  clickHandler() {
    if (!this.task) {
      this.setState({
        placeholder:""
      })
    }
  }

  render() {
    return <div>
      <p className="task"
        contentEditable="true"
        onClick={this.clickHandler.bind(this)}
        onKeyPress={this.keyPressHandler.bind(this)}>{this.state.task || this.state.placeholder}</p>
    </div>
  }
}
import React, {Component} from "react";

// Components
import Timer from "../../components/Timer";
import Task from "../../components/Task";
import TaskInput from "../../components/TaskInput";
import TaskHistoryTable from "../../components/TaskHistoryTable";

class App extends Component {
  constructor(props) {
    super(props);
    let _history = localStorage.getItem("history");
    _history = _history
      ? JSON.parse(_history)
      : [];
    this.state = {
      task: "",
      history: _history
    };
  }

  setTask(task) {
    this.setState({task: task});
  }

  resetTask() {
    this.setState({task: ""});
  }

  completedTask(sec) {
    console.log("Task Completed In :", this.state.task, sec);
    let history = [...this.state.history];
    history.push({task: this.state.task, counter: sec, timestamp: new Date().toISOString()});
    localStorage.setItem("history", JSON.stringify(history));
    this.setState({history: history});
  }

  render() {
    console.log("History : ", this.state.history);
    return (<div className="app">
      <div className="container">
        {
          this.state.task
            ? (<div>
              <Task task={this.state.task}/>
              <Timer resetTask={this.resetTask.bind(this)} completedTask={this.completedTask.bind(this)}/>
            </div>)
            : (<TaskInput setTask={this.setTask.bind(this)}/>)
        }
        <TaskHistoryTable history={this.state.history.reverse()}/>
      </div>
    </div>);
  }
}

export default App;